import React from "react";
import Logo from "src/components/MandalaUI/Logo";
import { useRouter } from "next/router";
import AppShell from "src/components/AppShell";
import { BackgroundWrapper } from "src/components/MandalaUI/BackgroundWrapper";
import { Loader, LOADER_TYPE_MAP } from "src/components/MandalaUI/Loader";
import { LoginForm } from "./Fragments";
import { Flex, Stack, Text } from "@chakra-ui/react";
import { useFetchUserStatusQuery } from "src/hooks/queries/fetches/useFetchUserStatusQuery";
import { REACT_QUERY_STATUS } from "src/constants/reactQueryStatus";
import { useAuthContext } from "src/contexts/auth";
import { createErrorToast } from "src/hooks/useToast";
import { clearToken } from "src/utils/tokenHelper";

const LoginPageContent = () => {
  const { push } = useRouter();
  const { isAuthenticated } = useAuthContext();
  const isUserAuthenticated = isAuthenticated();

  // this state is needed to prevent isCheckingAuth changed into false.
  // happens when user is authenticated, login component will render
  // a split second before user redirected into home page, we need to
  // prevent this behavior with _isCheckingAuth state
  const [_isCheckingAuth, setIsCheckingAuth] = React.useState(true);

  // direct to Home Page when user is authenticated
  const { status: userStatusRequestStatus } = useFetchUserStatusQuery({
    config: {
      onSuccess: () => {
        push("/home");
      },
      onError: error => {
        if (error?.statusCode === 423) {
          clearToken();
          push("/verify-product");
          createErrorToast(error?.message, { toastId: "PRODUCT_LOCKED" });
          return;
        }

        // only create error toast if user session is invalid (user was logged in)
        if (isUserAuthenticated) {
          createErrorToast(error.message);
        }
        setIsCheckingAuth(false);
        // clear any logged user token to avoid error toast appearing each refresh
        clearToken();
      }
    }
  });

  const isCheckingAuth =
    userStatusRequestStatus === REACT_QUERY_STATUS.LOADING || _isCheckingAuth;

  return (
    <BackgroundWrapper>
      <Stack
        data-testid="login-page-dialog"
        backgroundColor="white"
        borderRadius="10px"
        w={{ base: "20em", md: "25em" }}
        p="1.5em"
        spacing="1.5em"
        h="35em"
      >
        <Flex w="100%" justifyContent="center">
          <Logo textAlign="center" fontSize="2.25rem" isLink={false} />
        </Flex>
        <Flex h="100%" w="100%" justifyContent="center" direction={"column"}>
          {isCheckingAuth ? (
            <Loader
              data-testid="auth-loader"
              type={LOADER_TYPE_MAP.SPINNER}
              direction={"column"}
            >
              <Text pt="1em">Please wait...</Text>
            </Loader>
          ) : (
            <LoginForm />
          )}
        </Flex>
      </Stack>
    </BackgroundWrapper>
  );
};
export const LoginPage = () => {
  return (
    <AppShell>
      <LoginPageContent />
    </AppShell>
  );
};
