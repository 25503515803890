import React from "react";
import { Flex } from "@chakra-ui/react";

export const BackgroundWrapper = ({ children, ...props }) => {
  return (
    <Flex
      h="inherit"
      w="100%"
      justifyContent="center"
      alignItems="center"
      backgroundColor="primary"
      // backgroundImage={`url("/images/logo-background.svg")`}
      backgroundRepeat="no-repeat"
      backgroundPosition="top right"
      backgroundSize="60vh"
      {...props}
    >
      {children}
    </Flex>
  );
};
